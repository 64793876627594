<template>
  <v-container fluid class="pa-0 ma-0">
    <v-navigation-drawer permanent dark app class="fill-height">
      <v-list-item>
        <v-list-item-content class="pa-0 ma-0">
          <v-list-item-title>
            <v-img
                :src="require('../assets/logo.png')"
                contain
            width="256px"/>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list
          dense
          nav
      >
        <v-list-item
            v-for="item in items"
            :key="item.title"
            link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <chat/>
  </v-container>
</template>

<script>
import HelloWorld from '../components/HelloWorld'
import Chat from "@/components/chat";

export default {
  name: 'Home',
  data() {
    return {
      items: [
        {title: 'Serveur cosplay collège', icon: 'mdi-server'},
      ],
      right: null,
    }
  },
  components: {
    Chat,
    HelloWorld,
  },
}
</script>
