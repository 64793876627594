<template>
  <v-container fluid>
    <v-toolbar class="" style="background-color: rebeccapurple" dark>
      <h2>Serveur cosplay collège</h2>
    </v-toolbar>
    <v-row>
      <v-col cols="12">
        <v-list dark v-if="!loading">
          <template v-for="(message, index) in messages">
            <v-row no-gutters :key="index">
              <v-col :offset="message.pseudo=='NicolasDu25'?2:0" :cols="message.pseudo=='NicolasDu25'?10:10">
                <v-list-item
                    v-show="message.pseudo"
                    :key="message.title"
                >
                  <v-list-item-avatar class="green" v-if="message.pseudo!='NicolasDu25'">
                    <v-img :src="message.avatar"></v-img>
                    {{ message.pseudo }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="caption">{{message.date}}</span>
                      <v-alert
                          dense
                          :color="message.pseudo=='NicolasDu25'?'grey':'indigo'"
                          :class="message.pseudo=='NicolasDu25'?'text-right':''"
                          :border="message.pseudo=='NicolasDu25'?'right':'left'"
                          elevation="2"
                          style="    white-space: pre-wrap;"
                      >{{ message.message }}
                      </v-alert>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-avatar class="green" v-if="message.pseudo=='NicolasDu25'">

                    <v-img :src="require('../assets/avatar/NicolasDu25.jpg')"/>
                  </v-list-item-avatar>
                </v-list-item>
              </v-col>
              <v-col :cols="message.pseudo=='NicolasDu25'?0:2"></v-col>
            </v-row>
          </template>
        </v-list>
        <v-col cols="8" offset="2" v-else align-self="center" class="text-center">
          Chargement des messages en cours...
          <v-progress-circular indeterminate size="50" color="green"></v-progress-circular>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "chat",
  data() {
    return {
      items: [
        {title: 'Serveur cosplay collège', icon: 'mdi-server'},
      ],
      messages: [{
        "date":"11/08/2016",
        "pseudo": "NicolasDu25",
        "message": "Slt ! Bienvenue sur le groupe ! ",
        "estUneRponseAuMessagePrcdent": false,
        "commentaires": "J-1 : mois arrivée de Noelia"
      },],
      right: null,
      loading: false,
    }
  },
  methods: {
    sortAsc() {
      this.messages.sort()
    },
    sortDesc() {
      this.messages.sort().reverse()
    }
  },
  created() {
    this.loading = true;
    axios.get('/messages.json').then(data => {
      this.messages = data.data;
    }).catch(e => {
      console.error(e)
    }).finally(() => {
      this.loading = false;
    })
  },
}
</script>

<style scoped>

</style>