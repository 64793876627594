<template>
  <v-app class="theme--dark theme">
    <v-app-bar
        app
        color="green light2"
        dark
        clipped-left
        v-if="isLoggedIn"
    >
      <v-row no-gutters align="start" @click="$router.push({name:'Home'})">
        <v-col cols="12" align-self="start">
        </v-col>
      </v-row>
      <v-spacer/>
      <v-btn fab @click="logout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapGetters(['isLoggedIn'])
  },
  data: () => ({
    //
  }),
  created() {
    if (!this.isLoggedIn) {
      this.$router.push('/login');
    }
  },
  methods: {
    ...mapActions(['logout']),
  },
  watch: {
    'isLoggedIn': function () {
      if (!this.isLoggedIn) {
        this.$router.push('/login');
      }
    }
  }

};
</script>
<style>
.theme {
  background-image: linear-gradient(to right, rgb(38, 40, 42), rgb(57, 59, 61), rgb(72, 74, 76)) !important;
}
</style>
