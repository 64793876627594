import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loggedIn: false,
        passWord: "DomoArigato25",
        login: "NicolasDu25"
    },
    getters: {
        isLoggedIn(state) {
            return state.loggedIn
        }
    },
    mutations: {},
    actions: {
        async login({state}, payload) {
            return new Promise((resolve, reject) => {
                if (state.passWord == payload.password && state.login === payload.login ) {
                    console.log('ok')
                    state.loggedIn = true;
                    return resolve(true);
                } else {
                    state.loggedIn = false;
                    window.alert("Mauvais mot de passe!")
                    return reject(false);
                }
            })
        },
        async logout({state}) {
            return new Promise((resolve, reject) => {
                state.loggedIn = false;
                return resolve(true);
            })
        }
    },
    modules: {}
})
